export const rand = (length=10) => {
    let s = '';
    do { s += Math.random().toString(36).substr(2); } while (s.length < length);
    s = s.substr(0, length);
    
    return s;
}

export const getCatLetter = Nr => {
    switch (Nr){
        case 4 : return 'A';
        case 3 : return 'B';
        case 2 : return 'C';
        case 1 : return 'D';
        default : return '';
    }
}

export const randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const onNull = text => {
    if (text === null) return '';
    return text;
}

export const textToCaps = (text, dash) => {
    let str = text;

    if (dash) str = str.split('-').join(' ');
    return str.replace(/(^|[\s-])\S/g, function (match) {
        return match.toUpperCase();
    });
}

export const dateToString = date => {
  return  date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substr(0,10) : '';
}

export const stringToDate = (dateStr) => {
    if (dateStr === '' || dateStr === undefined || dateStr === null) return '';
    const year = dateStr.toString().substr(0,4);
    const month = dateStr.toString().substr(5,2);
    const day = dateStr.toString().substr(8,2);
  
    const hour = dateStr.toString().substr(11,2);
    const minute = dateStr.toString().substr(14,2);
    const second = dateStr.toString().substr(17,2);
  
    return new Date(year,parseInt(month-1),day,hour,minute,second);
  }

  export const stringToTime = dateStr => {
    const year = dateStr.toString().substr(0,4);
    const month = dateStr.toString().substr(5,2);
    const day = dateStr.toString().substr(8,2);
  
    const hour = dateStr.toString().substr(11,2);
    const minute = dateStr.toString().substr(14,2);
    const second = dateStr.toString().substr(17,2);
  
    return new Date(year,parseInt(month-1),day,hour,minute,second).getTime();
  }

export function isEmpty(obj) {
    if (obj === null || obj === undefined || obj === "" || obj === "null") return true;
    return Object.keys(obj).length === 0;
}


export const firsLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const eraseCookie = name => {   
  document.cookie = name+'=; Max-Age=-99999999;';  
}

/* create cookie */
export const createCookie = (name,value = 1 ,hours = 8760) => {
  if (hours) {
      var date = new Date();
      date.setTime(date.getTime()+(hours*60*60*1000));
      var expires = "; expires="+date.toGMTString();
  }
  else var expires = "";
  document.cookie = name+"="+value+expires+"; path=/";
}

/* read cookie */
export const readCookie = name => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}