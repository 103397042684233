import { createGlobalStyle } from 'styled-components';
import { viewMax, viewSd, viewHdSmall } from './actions/variables';
import loadingImage from './assets/images/loading.gif';

require('typeface-roboto');

const StylesGlobal = createGlobalStyle`

    :root{
        --color-base: #2ea3f2;
        --color-base-light: #d6eeff;
        --color-grey: #e2e3e5;
        --color-base-dark: #066791;
        --color-lime: #a9c028;
        --color-lime-dark: #9eb60a;
        --color-logo: #a2b527;
        --text-color-grey: #3e3e3e;

        --width-max: 1400px;
        --width-hd: 1200px;
    }

    *{
        box-sizing: border-box;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
    }

    #fb-root .fb_dialog .fb_dialog_content iframe{ bottom: 50px !important; }

    body{
        background-color: white;
        padding: 0px;
        margin: 0px;
        .contentWidth{
            width: var(--width-max);
            margin: 0px auto;
            display: flex;
            flex-direction: column;
            h1.ch1{
                font-size: 26px;
                font-weight: 500;
                margin: 10px 0px 20px 0px;
            }
            &.underPage{
                a{
                    color: #34a3f2;
                    text-decoration: none;
                    transition: all 0.3s;
                    &:hover{
                        text-decoration: underline;
                        color: #16659c;
                    }
                }
            }
            @media (max-width: ${viewMax}px){
                width: 100%;
                &.underPage{
                    padding: 0px 20px;
                }
            }
        }
        .marki{
            .bank{ 
                width: 20%; 
                float: left; 
                box-sizing: border-box; 
                text-align: center; 
                padding: 10px; 
                height: 140px; 
                margin-bottom: 10px; 
                figure{ 
                    display: block; 
                    margin: 0px; 
                    padding: 15px 10px 0px 10px; 
                    box-sizing: border-box; 
                    background: white; 
                    height: 100%; 
                    border: 1px solid #c7c7c7; 
                    transition: all 0.3s;
                    &:hover{ 
                        border-color: black; 
                    }
                    img{ 
                        width: 100%; 
                        max-height: 100%; 
                    }
                }
            }

        }
        .loadingLayer{
            position: relative;
            &:after{
                content: '';
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                z-index: 300;
                background-color: rgba(255,255,255,0.6);
            }
            &:before{
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -8px;
                margin-top: -8px;
                width: 16px;
                height: 16px;
                z-index: 301;
                background-image: url(${loadingImage});
            }
        }
        @media (max-width: ${viewHdSmall}px){
            .marki{
                .bank{ 
                    width: 33%; 
                    height: 130px; 
                }

            }
        }
        @media (max-width: ${viewSd}px){
            .marki{
                .bank{ 
                    width: 50%; 
                    height: 130px; 
                }

            }
            .layerTable{
                width: 100%;
                overflow-x: auto;
                table{
                    max-width: 600px !important;
                }
            }
            #chat-application[style*="background: transparent"]{
                bottom: 55px !important;
            }
        }
        
    }
`;	

export default StylesGlobal;