import React from 'react';

import { FaSignOutAlt, FaCog } from 'react-icons/fa';
import { FaUser as IcoUser } from 'react-icons/fa';

import UserStyle from './UserStyle';
import api from '../../../actions/api';

const User = ({ userData , logout, setShowForget, userToken, setTkn }) => {

    const handlePassword = event => {
        event.preventDefault();

        const userPlace = document.querySelector('#userPlace');

        if (userPlace){
            userPlace.classList.add('loadingLayer');
            api('createPasswordToken',userToken,{}, r => {
                userPlace.classList.remove('loadingLayer');
                if (r.result){
                    setTkn(r.data.token);
                    setShowForget(true);
                }
            });
        }

    }

    return (
        <UserStyle id="userPlace">
            <div href="showUserData" className="userName"><IcoUser /> {userData.name} {userData.surname}</div>
            <ul>
                <li><a href="changePassword" onClick={handlePassword}><FaCog /> Zmień hasło dostępu</a></li>
                <li><a href="logout" onClick={logout}><FaSignOutAlt /> Wyloguj</a></li>
            </ul>
        </UserStyle>
    );
}
export default User;