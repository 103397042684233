import styled from 'styled-components';
import { viewHd , viewMobile , viewHdSmall } from '../../actions/variables';

const MapStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 615px;
    flex-direction: row;
    margin-top: 50px;
    .contact{
        flex: 1;
        background-color: #f2f0f1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .content{
            flex: 1;
            width: 700px;
            display: flex;
            flex-direction: column;
            @media (max-width: 1400px){
                width: 100%;
            }
            .logo{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1;
            }
            .contactData{
                display: flex;
                flex-direction: row;
                /* align-items: center; */
                justify-content: center;
                flex: 1;
                min-height: 350px;
                .data{
                    max-width: 300px;
                    padding: 0px 20px 0px 20px;
                    &:nth-child(1){
                        padding-left: 0px;
                    }
                    &:nth-child(2){
                        padding-right: 0px;
                    }
                    header{
                        font-size: 19px;
                        font-weight: 500;
                    }
                    a{
                        text-decoration: none;
                        color: inherit;
                        &:hover{
                            text-decoration: underline;
                        }
                    }

                }
            }
        }
    }
    .map{
        flex: 1;
        .marker{
            display: block;
            width: fit-content;
            .imageLogo{
                width: 80px;
                img{
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: ${viewHd}px){
        .contact{
            min-width: 55%;
        }
    }
    @media (max-width: ${viewHdSmall}px){
        display: block;
        .contact{
            min-width: auto;
            padding: 100px 0px 100px 0px;
            .content{
                .contactData{
                    min-height: 0px;
                }
            }
        }
        .map{
            width: 100%;
            height: 400px;
        }
    }
    @media (max-width: ${viewMobile}px){
        display: block;
        margin-bottom: 50px;
        .contact{
            min-width: auto;
            padding: 50px 0px 50px 0px;
            .content{
                .logo{
                    display: block;
                    text-align: center;
                }
                .contactData{
                    display: block;
                    .data{
                        max-width: none;
                        text-align: center;
                        padding: 25px;
                        &:nth-child(1){
                            padding: 25px;
                        }
                        ul{
                            display: block;
                            list-style: none;
                            margin: 10px 0px 0px 0px;
                            padding: 0px;
                            li{
                                padding: 0px;
                            }
                        }
                    }
                }
            }
        }
        .map{
            width: 100%;
            height: 400px;
        }
    }
`;	

export default MapStyle;